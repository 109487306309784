import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentUser: null,
    selectedCategoriesArr: null,
    collectionCategories: null,
    actionCategories: null,
    cart: null,
    order: null,
    error: null,
    loading: false,
};

const userSlice = createSlice ({
    name: 'user',
    initialState,
    reducers: {
        neutral: (state) => {
            state.loading = false;
            state.error = null;
        },
        registerStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        registerSuccess: (state, action) => {
            state.currentUser = action.payload;
            state.loading = false;
            state.error = null;
        },
        registerFailure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        loginStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        loginSuccess: (state, action) => {
            state.currentUser = action.payload;
            state.loading = false;
            state.error = null;
        },
        updateUserSuccess: (state, action) => {
            state.currentUser = action.payload;
            state.loading = false;
            state.error = null;
        },
        loginFailure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        logoutStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        logoutSuccess: (state) => {
            state.currentUser = null;
            state.loading = false;
            state.error = null;
        },
        logoutFailure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        updateCart: (state, action) => {
            state.cart = action.payload;
        },
        updateOrder: (state, action) => {
            state.order = action.payload;
        },
        updateSelectedCategoriesArr: (state, action) => {
            state.selectedCategoriesArr = action.payload;
        },
        updateCollectionCategories: (state, action) => {
            state.collectionCategories = action.payload;
        },
        updateActionCategories: (state, action) => {
            state.actionCategories = action.payload;
        },
    }
})

export const { neutral, registerStart, registerSuccess, registerFailure, loginStart, loginSuccess, updateUserSuccess, loginFailure, logoutStart, logoutSuccess, logoutFailure, updateCart, updateOrder, updateSelectedCategoriesArr, updateCollectionCategories, updateActionCategories } = userSlice.actions;

export default userSlice.reducer;