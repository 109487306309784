import React, { useEffect, useState } from "react";
import DurkImg4 from "../../assets/images/durkimg4.jpg";
import Logo2 from "../../assets/images/logo2.png";
import { Link } from "react-router-dom";
import axios from "axios";
import ClampLines from "react-clamp-lines";
import { useSelector } from "react-redux";

const TopPicks = () => {
  const { collectionCategories, actionCategories } = useSelector(
    (state) => state.user
  );
  const [collectionTop, setCollectionTop] = useState([]);
  const [actionTop, setActionTop] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [collectionLoading, setCollectionLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (actionCategories?.length > 0) {
      setActionLoading(true);
      let actionIds = actionCategories.map((category) => {
        return category._id;
      });
      axios
        .get(
          `${
            process.env.REACT_APP_BACKEND_URL
          }/products?sort=rating&limit=6&categoryid=${actionIds.join(",")}`
        )
        .then((res) => {
          setActionLoading(false);
          setActionTop(res.data);
        })
        .catch((err) => {
          setError(err.response.data.message);
          setActionLoading(false);
        });
    }
  }, [actionCategories]);

  useEffect(() => {
    if (collectionCategories?.length > 0) {
      setCollectionLoading(true);
      let collectionIds = collectionCategories.map((category) => {
        return category._id;
      });
      axios
        .get(
          `${
            process.env.REACT_APP_BACKEND_URL
          }/products?sort=rating&limit=6&categoryid=${collectionIds.join(",")}`
        )
        .then((res) => {
          setCollectionLoading(false);
          setCollectionTop(res.data);
        })
        .catch((err) => {
          setError(err.response.data.message);
          setCollectionLoading(false);
        });
    }
  }, [collectionCategories]);

  return (
    <>
      <div id="section3" className="container-fluid">
        <h1>COLLECTIBLES</h1>
        <br />
        <div className="products row" style={{ minHeight: "420px" }}>
          {!actionLoading &&
            actionTop &&
            actionTop.map((product, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-sm-6">
                <Link to={`/product/${product._id}`} className="productLink">
                  <div className="card" style={{ width: "fit-content" }}>
                    <div className="card-body s3cardbody1">
                      <span className="s3cardspan me-2">
                        <img src={Logo2} className="s3cardlogo" alt="" />
                      </span>
                      CROWNMANIA
                    </div>
                    <div className="productimg text-center">
                      <img
                        src={product.images[0]}
                        className="cardimg"
                        alt={product.name}
                      />
                    </div>
                    <div className="card-body">
                      <ClampLines
                        text={product.name}
                        id="product-name"
                        lines={1}
                        ellipsis="..."
                        buttons={false}
                        innerElement="p"
                        className="card-text"
                      />
                      <div className="card-text d-flex justify-content-between flex-wrap">
                        <span>${product.price}</span>
                        <span className="whitetext">
                          {product?.rating.toFixed(1)}⭐
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
        <br />
        <div className="text-center">
          <Link to="/products" className="btn btnbgwhite wallet3">
            VIEW MORE
          </Link>
        </div>
      </div>
      {/* {<div id="section3" className="container-fluid">
        <h1>COLLECTION FIGURES</h1>
        <br />
        <div className="products row" style={{ minHeight: "420px" }}>
          {!collectionLoading &&
            collectionTop &&
            collectionTop.map((product, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-sm-6">
                <Link to={`/product/${product._id}`} className="productLink">
                  <div className="card" style={{ width: "fit-content" }}>
                    <div className="card-body s3cardbody1">
                      <span className="s3cardspan me-2">
                        <img src={Logo2} className="s3cardlogo" alt="" />
                      </span>
                      CROWNMANIA
                    </div>
                    <div className="productimg text-center">
                      <img
                        src={product.images[0]}
                        className="cardimg"
                        alt={product.name}
                      />
                    </div>
                    <div className="card-body">
                      <ClampLines
                        text={product.name}
                        id="product-name"
                        lines={1}
                        ellipsis="..."
                        buttons={false}
                        innerElement="p"
                        className="card-text"
                      />
                      <div className="card-text d-flex justify-content-between flex-wrap">
                        <span>${product.price}</span>
                        <span className="whitetext">
                          {product?.rating.toFixed(1)}⭐
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
        <br />
        <div className="text-center">
          <Link to="/products" className="btn whitebutton">
            VIEW MORE
          </Link>
        </div>
      </div>} */}
    </>
  );
};

export default TopPicks;
