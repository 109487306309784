import React, { useEffect, useState } from "react";
import toasty from "../utils/Toast";
import axios from "axios";
import Logo from "../assets/images/logo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  logoutSuccess,
  updateActionCategories,
  updateCollectionCategories,
} from "../redux/user/userSlice";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser, collectionCategories, actionCategories } = useSelector(
    (state) => state.user
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Mouseover and mouseout event handlers
    const handleMouseOver = () => {
      document.getElementById("rotation1").classList.toggle("rotated");
      const scroll = window.scrollY;
      if (scroll > 0) {
        document.querySelector(".navbar").classList.toggle("translucent");
      }
      document.querySelector(".navbar").classList.toggle("navback");
    };

    const handleMouseOut = () => {
      document.querySelector(".navbar").classList.toggle("navback");
      const scroll = window.scrollY;
      if (scroll > 0) {
        document.querySelector(".navbar").classList.toggle("translucent");
      }
      document.getElementById("rotation1").classList.toggle("rotated");
    };

    // Adding event listeners
    const drop1Elements = document.querySelectorAll(".drop1");
    drop1Elements.forEach((element) => {
      element.addEventListener("mouseover", handleMouseOver);
      element.addEventListener("mouseout", handleMouseOut);
    });

    // Cleanup event listeners
    return () => {
      drop1Elements.forEach((element) => {
        element.removeEventListener("mouseover", handleMouseOver);
        element.removeEventListener("mouseout", handleMouseOut);
      });
    };
  }, []); // Empty dependency array ensures this effect runs only once after initial render

  // State for handling scroll position
  const [prevScrollpos, setPrevScrollpos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.getElementById("navwrap").style.top = "0";
      } else {
        document.getElementById("navwrap").style.top = "-100px";
      }
      setPrevScrollpos(currentScrollPos);

      const scroll = window.scrollY;
      const navbar = document.querySelector(".navbar");
      if (scroll > 1) {
        navbar.classList.add("translucent");
      } else if (scroll === 0) {
        navbar.classList.remove("translucent");
      }
    };

    // Adding scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup scroll event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollpos]); // Dependency on prevScrollpos ensures this effect runs whenever prevScrollpos changes

  const handleLogout = async (e) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `http://localhost:5000/logout`
      );
      console.log(`http://localhost:5000`);
      setLoading(false);
      toasty(res.data.message, "success");
      dispatch(logoutSuccess(res.data.user));
      navigate("/");
    } catch (err) {
      setError(err.message);
      setLoading(false);
      toasty(err.response.data.message, "error");
    }
  };

  const [actionCategory, setActionCategory] = useState([]);
  const [collectionCategory, setCollectionCategory] = useState([]);
  useEffect(() => {
    const getActionCategory = async () => {
      try {
        const response = await axios.get(
          `http://localhost:5000/categories?type=action`
        );
        setActionCategory(response.data.categories);
      } catch (error) {
        setError(error.message);
      }
    };
    const getCollectionCategory = async () => {
      try {
        const response = await axios.get(
          `http://localhost:5000/categories?type=collection`
        );
        setCollectionCategory(response.data.categories);
      } catch (error) {
        setError(error.message);
      }
    };
    if (actionCategory.length === 0) getActionCategory();
    if (collectionCategory.length === 0) getCollectionCategory();
  }, []);

  useEffect(() => {
    if (actionCategory.length > 0) {
      dispatch(updateActionCategories(actionCategory));
    }
  }, [actionCategory]);

  useEffect(() => {
    if (collectionCategory.length > 0) {
      dispatch(updateCollectionCategories(collectionCategory));
    }
  }, [collectionCategory]);

  return (
    <div id="navwrap">
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="">
              <i className="fa-solid fa-bars" style={{ color: "white" }}></i>
            </span>
          </button>
          <Link to="/" className="navbar-brand">
            <img src={Logo} id="navimg" alt="" />
          </Link>
          {/* <!-- Offcanvas --> */}
          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                <img src={Logo} id="navimg" alt="" />
              </h5>
              <button
                type="button"
                id="navclosebutton"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <i className="fa-solid fa-xmark" style={{ color: "white" }}></i>
              </button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li className="nav-item" >
                  <NavLink to="/" className="nav-link whitetext">
                    {" "}
                    <span type="button"
                      id="navclosebutton"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close">  HOME </span>{" "}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/products" className="nav-link whitetext">
                    {" "}
                    <span type="button"
                      id="navclosebutton"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close">SHOP</span>{" "}
                  </NavLink>
                </li>
                {/* {<li className="nav-item">
                  <NavLink to="/blogs" className="nav-link whitetext">
                    {" "}
                    BLOGS{" "}
                  </NavLink>
                </li>} */}
                <li className="nav-item">
                  <NavLink to="/contactus" className="nav-link whitetext">
                    {" "}
                    <span type="button"
                      id="navclosebutton"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close">CONTACT US</span>{" "}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/aboutus" className="nav-link whitetext">
                    {" "}
                    <span type="button"
                      id="navclosebutton"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close">ABOUT US</span>{" "}
                  </NavLink>
                </li>
                {currentUser ? (
                  <>
                    <li className="nav-item">
                      <NavLink to="/profile" className="nav-link whitetext">
                        {" "}
                        <span type="button"
                          id="navclosebutton"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close">PROFILE</span>{" "}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <button
                        onClick={handleLogout}
                        disabled={loading}
                        className="nav-link whitetext"
                      >
                        <span> <span type="button"
                          id="navclosebutton"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close">Logout</span></span>
                        <i className="fa-solid fa-right-to-bracket ms-1"></i>
                      </button>
                    </li>
                  </>
                ) : (
                  <li className="nav-item">
                    <NavLink to="/login" className="nav-link whitetext">
                      <span><span type="button"
                        id="navclosebutton"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close">LOGIN</span></span>
                      <i className="fa-solid fa-right-to-bracket ms-1"></i>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </div>
          {/* <!-- Offcanvas --> */}
          <button
            className="navbar-toggler nonvisible"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink to="/" className="nav-link whitetext">
                  {" "}
                  HOME{" "}
                </NavLink>
              </li>
              <li className="nav-item">
                <div className="dropper">
                  <NavLink
                    to="/products"
                    className="nav-link whitetext drop1"
                    id="rotate1"
                  >
                    SHOP
                    <i
                      id="rotation1"
                      className="fa-solid fa-angle-down rotation ms-1"
                    ></i>
                  </NavLink>
                  <div
                    id="dropdown1"
                    className="container-fluid drop1 translucent2"
                  >
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <p className="droptitle">Action Figures</p>
                        {actionCategory?.map((category) => (
                          <p key={category._id} className="dropcontent">
                            <Link to={`/products?category=${category._id}`}>
                              {category.name}
                            </Link>
                          </p>
                        ))}
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <p className="droptitle">Collection Figures</p>
                        {collectionCategory?.map((category) => (
                          <p key={category._id} className="dropcontent">
                            <Link to={`/products?category=${category._id}`}>
                              {category.name}
                            </Link>
                          </p>
                        ))}
                      </div>
                      <div className="col-lg-4 col-md-12 col-sm-12">
                        <a href="#" className="dropimglink">
                          <div className="dropimg">
                            <div className="dropimgtop">
                              <p className="dropimgp">Lorem ipsum dolor sit.</p>
                            </div>
                            <div className="dropimgbottom">
                              <p className="dropimgp">
                                Lorem, ipsum dolor sit amet consectetur
                                adipisicing elit. Maxime, quibusdam.
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {/* {<li className="nav-item">
                <NavLink to="/blogs" className="nav-link whitetext">
                  {" "}
                  BLOGS{" "}
                </NavLink>
              </li>} */}
              <li className="nav-item">
                <NavLink to="/contactus" className="nav-link whitetext">
                  {" "}
                  CONTACT{" "}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/aboutus" className="nav-link whitetext">
                  {" "}
                  ABOUT US{" "}
                </NavLink>
              </li>
              {currentUser ? (
                <>
                  <li className="nav-item">
                    <NavLink to="/profile" className="nav-link whitetext">
                      {" "}
                      PROFILE{" "}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <button
                      onClick={handleLogout}
                      disabled={loading}
                      className="nav-link whitetext"
                    >
                      <span>Logout</span>
                      <i className="fa-solid fa-right-to-bracket ms-1"></i>
                    </button>
                  </li>
                </>
              ) : (
                <li className="nav-item">
                  <NavLink to="/login" className="nav-link whitetext">
                    <span>LOGIN</span>
                    <i className="fa-solid fa-right-to-bracket ms-1"></i>
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
