import React, { useEffect, useState } from 'react'
import toasty from '../utils/Toast';
import axios from 'axios';
import '../assets/css/login.css'
import { useLocation, useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        email: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/forgotpassword`, formData);
            setLoading(false);
            toasty(res.data.message, "success");
            setFormData({
                email: ''
            });
            navigate('/login');
        } catch (err) {
            setError(err.message);
            setLoading(false);
            toasty(err.response.data.message, "error")
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='login'>
            <div id="section1" className="container-fluid">
                <div className="form">
                    <h1 className="text-center">FORGOT PASSWORD</h1>
                    <br />
                    <form onSubmit={handleSubmit}>
                        <div className="form-floating mb-3">
                            <input
                                type="email"
                                className="form-control"
                                id="floatingInput"
                                placeholder="name@example.com"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="floatingInput">Email address</label>
                        </div>
                        <div className="d-grid gap-2">
                            <button type="submit" className="btn whitebutton" disabled={loading}>
                                {loading ? 'Loading...' : 'Forgot Password'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
