import React, { useEffect, useState } from "react";
import "../assets/css/profile.css";
import toasty from "../utils/Toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutSuccess, updateUserSuccess } from "../redux/user/userSlice";
import UserDetails from "../components/profile/UserDetails";
import durkimg4 from "../assets/images/durkimg4.jpg";
import OrderCard from "../components/profile/OrderCard";
import Loader from "../components/Loader";

const Profile = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const [orders, setOrders] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getOrders = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/orders/${currentUser?.id}`
      );
      setLoading(false);
      setOrders(res.data.orders);
      console.log(res.data);
    } catch (err) {
      setError(err.response.data.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    getOrders();
  }, [currentUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="profile">
      <div id="section1" className="container-fluid">
        <br />
        <br />
        <div className="text-center intro">PROFILE</div>
        <br />
        <br />
        <div className="row">
          <UserDetails />
          <div className="col-lg-6 col-md-6 col-sm-12 col2">
            <br />
            <h2 className="whitetext">Orders</h2>
            <hr className="whitehr" />
            {loading ? (
              <Loader />
            ) : (
              <div>
                {orders && orders.length === 0 && (
                  <p className="whitetext">No orders yet</p>
                )}
                {orders &&
                  orders.map(
                    (order) => <OrderCard key={order._id} order={order} />
                    // order.paid && <OrderCard key={order._id} order={order} />
                  )}
              </div>
            )}
            {/* <br />
                        <h2 className="whitetext">Minted NFTs</h2>
                        <hr className="whitehr" />
                        <div className="order">
                            <div className="product">
                                <div>
                                    <img src="../public/images/img11.png" className="prodimg" alt="" />
                                    <a href="#" className="productlink" style={{ textWrap: 'nowrap' }}>Product Name</a>
                                </div>
                                <div><h2>$20</h2></div>
                            </div>
                            <p>
                                <strong className="whitetext">Product: </strong> Product Name
                                <br />
                                <strong className="whitetext">Key: </strong> 123456789
                                <br />
                                <strong className="whitetext">Wallet key: </strong> 12356789
                                <br />
                            </p>
                            <div className="d-grid gap-2">
                                <a href="#" className="btn whitebutton">Download File</a>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
