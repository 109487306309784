import React from "react";
import { Link } from "react-router-dom";
import DurkImg1 from "../../assets/images/durkimg1.png";
import DurkImg2 from "../../assets/images/durkimg2.png";

const LatestCollections = () => {
  return (
    <div id="section2" className="container-fluid">
      <div className="row s2r">
        <div className="col-lg-6 col-md-12 col-sm-12 s2col1">
          <div>
            <h1>VIEW OUR LATEST</h1>
            <h1>DROP</h1>
            <br />
            <Link to="/products" className="btn  btnbgwhite wallet3">
              BROWSE COLLECTIONS
            </Link>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 s2col2">
          <div className="text-center">
            <img src={DurkImg1} className="s2img" alt="" />
            <img src={DurkImg2} className="s2img" alt="" />
            <img src={DurkImg1} className="s2img" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestCollections;
