import { Navigate, Outlet } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import '../assets/css/login.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import toasty from '../utils/Toast';

const SpecialRoute = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const [email, setEmail] = useState(searchParams.get('email'));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [verified, setVerified] = useState(false);
    const verifyEmail = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/verify/${params.token}?email=${email}`);
            toasty(res.data.message, "success");
            setVerified(true);
        } catch (error) {
            toasty(error.response.data.message, "error");
            setError(error.response.data.message);
            navigate('/verifyemail?email=' + email);
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        verifyEmail();
    }, []);
    return verified ? <Navigate to='/emailverified' /> : <Outlet />
}

export default SpecialRoute
