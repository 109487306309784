import React, { useEffect, useState } from 'react'
import toasty from '../utils/Toast';
import axios from 'axios';
import '../assets/css/login.css'
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        phone: '',
        password: ''
    });

    const handleTogglePassword = () => {
        setShowPassword(prevState => !prevState);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/signup`, formData);
            setLoading(false);
            toasty(res.data.message, "success");
            setFormData({
                firstname: '',
                lastname: '',
                username: '',
                email: '',
                phone: '',
                password: ''
            });
            navigate('/login');
        } catch (err) {
            setError(err.response.data.message);
            setLoading(false);
            toasty(err.response.data.message, "error")
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="login">
            <div id="section1" className="container-fluid">
                <div className="form">
                    <h1 className="text-center">SIGNUP</h1>
                    <br />
                    <form onSubmit={handleSubmit}>
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="floatingInputfirstname"
                                placeholder="First Name"
                                name="firstname"
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="floatingInputfirstname">First Name</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="floatingInputlastname"
                                placeholder="Last Name"
                                name="lastname"
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="floatingInputlastname">Last Name</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="floatingInputusername"
                                placeholder="Username"
                                name="username"
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="floatingInputusername">Username</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input
                                type="email"
                                className="form-control"
                                id="floatingInput"
                                placeholder="name@example.com"
                                name="email"
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="floatingInput">Email Address</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input
                                type="number"
                                className="form-control"
                                id="floatingInputnumber"
                                placeholder="1234567890"
                                name="phone"
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="floatingInputnumber">Phone Number</label>
                        </div>
                        <div className="form-floating">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                className="form-control"
                                id="password"
                                placeholder="Password"
                                name="password"
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="password">Password</label>
                        </div>
                        <br />
                        <div className="mb-3 form-check">
                            <input type="checkbox" className="form-check-input" id="check" onChange={handleTogglePassword}
                                checked={showPassword} />
                            <label className="form-check-label" htmlFor="check">Show Password</label>
                        </div>
                        <div className="d-grid gap-2">
                            <button type="submit" className="btn whitebutton" disabled={loading}>
                                {loading ? 'Loading...' : 'Sign Up'}
                            </button>
                        </div>
                        <br />
                        <p className="text-center or">OR</p>
                        <div className="d-grid gap-2">
                            <a type="submit" href="/login" className="btn whitebutton">Login</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SignUp
