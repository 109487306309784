import React, { useEffect, useState } from "react";
import toasty from "../../utils/Toast";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateUserSuccess } from "../../redux/user/userSlice";
const UserDetails = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const [showEditForm, setShowEditForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    first_name: currentUser.firstname,
    last_name: currentUser.lastname,
    username: currentUser.username,
    email: currentUser.email,
    phone: currentUser.phone,
    password: currentUser.password,
    address: currentUser.address,
    city: currentUser.city,
    zip: currentUser.zip,
    country: currentUser.country,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/update`,
        formData
      );
      setLoading(false);
      toasty(res.data.message, "success");
      dispatch(updateUserSuccess(res.data.user));
      handleCancelEditClick();
    } catch (err) {
      setError(err.response.data.message);
      setLoading(false);
      toasty(err.response.data.message, "error");
    }
  };

  const handleChangePassword = async (e) => {
    setChangePasswordLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/forgotpassword`,
        { email: currentUser.email }
      );
      setChangePasswordLoading(false);
      toasty(res.data.message, "success");
    } catch (err) {
      setChangePasswordLoading(false);
      toasty(err.response.data.message, "error");
    }
  };

  useEffect(() => {
    // This code will execute when the component mounts
    // Hide the edit form and cancel button initially
    document.getElementById("editdetailsform").style.display = "none";
    document.getElementById("canceleditbutton").style.display = "none";
  }, []);

  const handleEditClick = () => {
    // Show the edit form and cancel button, hide the table and edit button
    document.getElementById("editdetailsform").style.display = "block";
    document.getElementById("canceleditbutton").style.display = "inline";
    document.querySelector("table").style.display = "none";
    document.getElementById("editbutton").style.display = "none";
  };

  const handleCancelEditClick = () => {
    // Hide the edit form and cancel button, show the table and edit button
    document.getElementById("editdetailsform").style.display = "none";
    document.getElementById("canceleditbutton").style.display = "none";
    document.querySelector("table").style.display = "block";
    document.querySelector("table").style.margin = "auto";
    document.querySelector("table").style.width = "300px";
    document.getElementById("editbutton").style.display = "inline";
  };
  return (
    <div className="col-lg-6 col-md-6 col-sm-12 col1">
      <p className="text-center">
        <i className="fa-solid fa-user fa-4x" style={{ color: "#d7b762" }}></i>
      </p>
      <table>
        <tr>
          <td className="whitetext">First Name</td>
          <td>{currentUser.firstname}</td>
        </tr>
        <tr>
          <td className="whitetext">Last Name</td>
          <td>{currentUser.lastname}</td>
        </tr>
        <tr>
          <td className="whitetext">Username</td>
          <td>{currentUser.username}</td>
        </tr>
        <tr>
          <td className="whitetext">Email</td>
          <td>{currentUser.email}</td>
        </tr>
        <tr>
          <td className="whitetext">Phone</td>
          <td>{currentUser.phone}</td>
        </tr>
        {/* <tr>
                                <td className="whitetext">Address</td>
                                <td>{currentUser.address}</td>
                            </tr>
                            <tr>
                                <td className="whitetext">City</td>
                                <td>{currentUser.city}</td>
                            </tr>
                            <tr>
                                <td className="whitetext">ZIP Code</td>
                                <td>{currentUser.zip}</td>
                            </tr>
                            <tr>
                                <td className="whitetext">Country</td>
                                <td>{currentUser.country}</td>
                            </tr> */}
      </table>
      <div
        className="form"
        id="editdetailsform"
        style={{ display: showEditForm ? "block" : "none" }}
      >
        <form onSubmit={handleSubmit}>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingInputfirstname"
              placeholder="First Name"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              required
            />
            <label htmlFor="floatingInputfirstname">First Name</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingInputlastname"
              placeholder="Last Name"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              required
            />
            <label htmlFor="floatingInputlastname">Last Name</label>
          </div>
          {/* <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInputusername"
                                        placeholder="Username"
                                        name="username"
                                        value={formData.username}
                                        onChange={handleChange}
                                        required
                                        disabled
                                    />
                                    <label htmlFor="floatingInputusername">Username</label>
                                </div> */}
          {/* <div className="form-floating mb-3">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="name@example.com"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                        disabled
                                    />
                                    <label htmlFor="floatingInput">Email Address</label>
                                </div> */}
          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control"
              id="floatingInputnumber"
              placeholder="1234567890"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <label htmlFor="floatingInputnumber">Phone Number</label>
          </div>
          {/* <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInputnumber"
                                        placeholder="Address"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label for="floatingInputnumber">Address</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInputnumber"
                                        placeholder="City"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label for="floatingInputnumber">City</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="floatingInputnumber"
                                        placeholder="123456"
                                        name="zip"
                                        value={formData.zip}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label for="floatingInputnumber">ZIP Code</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInputnumber"
                                        placeholder="Country"
                                        name="country"
                                        value={formData.country}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label for="floatingInputnumber">Country</label>
                                </div> */}
          <br />
          <div className="d-grid gap-2">
            <button
              type="submit"
              className="btn whitebutton buttonlink"
              disabled={loading}
            >
              {loading ? "Loading..." : "Update"}
            </button>
          </div>
        </form>
      </div>
      <br />
      <div className="buttons">
        <div className="button" id="editbutton">
          <a
            href="#"
            onClick={handleEditClick}
            className="btn whitebutton buttonlink"
          >
            Edit Details
          </a>
        </div>
        <div className="button" id="canceleditbutton">
          <a
            href="#"
            onClick={handleCancelEditClick}
            className="btn whitebutton buttonlink"
          >
            Cancel Edit
          </a>
        </div>
        <div
          className="button"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          <div className="btn whitebutton buttonlink">Change Password</div>
        </div>
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5 text-dark" id="exampleModalLabel">
                  Are you sure?
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body text-dark">
                A password reset link will be sent to your registered email
                address.
              </div>
              <div class="modal-footer justify-content-between">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  style={{ backgroundColor: "#000635" }}
                  onClick={handleChangePassword}
                  disabled={changePasswordLoading}
                >
                  Get Link
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
