import React, { useEffect } from 'react'
import '../assets/css/login.css'
import { Link } from 'react-router-dom'

const EmailVerified = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='login'>
            <div id="section1" className="container-fluid">
                <div className="form">
                    <h1 className="text-center">EMAIL VERIFIED</h1>
                    <br />
                    <p className="text-center">Your email has been verified successfully.</p>
                    <br />
                    <div className="d-grid gap-2">
                        <Link to="/login" className="btn whitebutton">
                            Login
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailVerified
