import React from 'react'
import Logo from '../assets/images/logo.png'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer className="container-fluid">
            <h4 className="designer">
                <img src={Logo} id="footlogo" alt="" /> CROWNMANIA
            </h4>
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 footrc1">
                    <div className="whitetext">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus
                        doloremque ducimus aliquam vitae aspernatur, hic, culpa itaque
                    </div>
                    <div>
                        <a href="#" target="_blank" className="footlink">
                            <i className="fa-brands fa-youtube fa-2x"></i>
                        </a>
                        <a href="#" target="_blank" className="footlink">
                            <i className="fa-brands fa-facebook fa-2x"></i>
                        </a>
                        <a href="#" target="_blank" className="footlink">
                            <i className="fa-brands fa-instagram fa-2x"></i>
                        </a>
                        <a href="#" target="_blank" className="footlink">
                            <i className="fa-brands fa-x-twitter fa-2x"></i>
                        </a>
                        <a href="#" target="_blank" className="footlink">
                            <i className="fa-brands fa-tiktok fa-2x"></i>
                        </a>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 footrc2 text-end">
                    <p><Link to="/products" className="footlink">Collections</Link></p>
                    <p><Link to="/products" className="footlink">Shop</Link></p>
                    <p><Link to="/aboutus" className="footlink">About Us</Link></p>
                    <p><Link to="/blogs" className="footlink">Blogs</Link></p>
                    <p><Link to="/profile" className="footlink">Shipping and Returns</Link></p>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 footrc3 text-end">
                    <p><Link to="/" className="footlink">Home</Link></p>
                    <p><Link to="/faqs" className="footlink">FAQs</Link></p>
                    <p><Link to="/privacypolicy" className="footlink">Privacy Policy</Link></p>
                    <p><Link to="/termsandconditions" className="footlink">Terms and Conditions</Link></p>
                    <p><Link to="/contactus" className="footlink">Contact Us</Link></p>
                </div>
            </div>
            <hr />
            <p className="text-center footlast">Copyright @CROWNMANIA, 2023</p>
        </footer>
    )
}

export default Footer
