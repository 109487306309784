import React, { useEffect, useState } from 'react'
import toasty from '../utils/Toast';
import axios from 'axios';
import '../assets/css/login.css'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutSuccess } from '../redux/user/userSlice';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('token');
  const email = searchParams.get('email');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    password: '',
    email: email,
    token: token
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/resetpassword`, formData);
      setLoading(false);
      toasty(res.data.message, "success");
      setFormData({
        password: ''
      });
      dispatch(logoutSuccess());
      navigate('/login');
    } catch (err) {
      setError(err.message);
      setLoading(false);
      toasty(err.response.data.message, "error")
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='login'>
      <div id="section1" className="container-fluid">
        <div className="form">
          <h1 className="text-center">RESET PASSWORD</h1>
          <br />
          <form onSubmit={handleSubmit}>
            <div className="form-floating mb-3">
              <input
                type="password"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                name="password"
                onChange={handleChange}
                required
              />
              <label htmlFor="floatingInput">New Password</label>
            </div>
            <input type="hidden" value="email" name="email" />
            <div className="d-grid gap-2">
              <button
                type="submit"
                name="email"
                value="<%= email %>"
                className="btn whitebutton"
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Reset Password'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
