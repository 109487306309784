import React, { useEffect, useState } from 'react'
import durkimg1 from '../assets/images/durkimg1.png';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { updateCart } from '../redux/user/userSlice';
import toasty from '../utils/Toast';
import '../assets/css/login.css'
import axios from 'axios';
import { loadStripe } from "@stripe/stripe-js";
import StripeCheckout from "react-stripe-checkout";
// import { useHistory } from "react-router";

const KEY = process.env.REACT_APP_STRIPE;
const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const qty = searchParams.get('qty') || 1;
  const { currentUser, cart, order } = useSelector(state => state.user);
  useEffect(() => {
    if (!cart) {
      navigate('/products');
    }
  }, [cart]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    productid: cart?.product?._id,
    userid: currentUser?.id,
    deliveryName: '',
    deliveryPhone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    qty: qty,
    totalAmount: cart?.product?.price * parseInt(qty)
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const [stripeToken, setStripeToken] = useState(null);
  // const history = useHistory();

  const onToken = (token) => {
    setStripeToken(token);
  };

  useEffect(() => {
    const makeRequest = async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/payment`, {
          tokenId: stripeToken.id,
          amount: formData.totalAmount,
        });
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/create-order`, formData);
        toasty(res.data.message, "success");
        navigate(`/order-successfull?orderid=${response.data.order._id}`);
        
        // history.push("/success", {
        //   stripeData: res.data,
        //   products: cart,
        // });
      } catch (err) {
        if (err.response) {
          toasty(err.response.data.message, "error");
        } else {
          toasty("Error! Try Again Later!", "error");
        }
      }
    };
    stripeToken && makeRequest();
  }, [stripeToken, cart]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/create-order`, formData);
      const stripeKey = process.env.REACT_APP_STRIPE
      const stripe = await loadStripe(stripeKey);
      // makePayment();
      const orderid = res.data.order._id;
      // const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, formData);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, {orderid: res.data.order._id});
      const session = await response.data;
      const result = stripe.redirectToCheckout({
        sessionId: session.id
      });

      if (result.error) {
        console.log(result.error);
      }
      else {
        console.log("GUddddddddddddddddd")

      }
      console.log("Response from stripe", response.data);
      console.log("Result from stripe", result);
      setLoading(false);
      // toasty(res.data.message, "success");
      // navigate('/');
    } catch (err) {
      console.log("Server err", err)
      setLoading(false);
      setError(err.response.data.message);
      toasty(err.response.data.message, "error");
    }
  };

  // const makePayment = async () => {

  //   const body = {
  //     name: "ujjawal",
  //     registrationId: "mkimnjnjb"
  //   }
  //   const headers = {
  //     "Content-Type": "application/json"
  //   }
  //   const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, {
  //     method: "POST",
  //     headers: headers,
  //     body: JSON.stringify(body)
  //   });

  //   const session = await response.json();

  //   const result = stripe.redirectToCheckout({
  //     sessionId: session.id
  //   });

  //   if (result.error) {
  //     console.log(result.error);
  //   }
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="login">
      <div id="section1" className="container-fluid">
        <div className="form">
          <h1 className="text-center fs-4">Complete your purchase</h1>
          <br />
          <p>
            <strong>Product:</strong> {cart?.product?.name}
            <br />
            <strong>Creator:</strong> {cart?.artist?.name}
            <br />
            <strong>Quantity:</strong> {qty}
            <br />
            <strong>Checkout Price:</strong> ${(cart?.product?.price * parseInt(qty)).toFixed(2)}
          </p>
          <h4 className='text-center fs-5'>Delivery Details</h4>
          <form onSubmit={handleSubmit}>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInputDeliveryName"
                placeholder="Name"
                name="deliveryName"
                value={formData.deliveryName}
                onChange={handleChange}
                required
              />
              <label htmlFor="floatingInputDeliveryName">Name</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="number"
                className="form-control"
                id="floatingInputDeliveryPhone"
                placeholder="Phone"
                name="deliveryPhone"
                value={formData.deliveryPhone}
                onChange={handleChange}
                required
              />
              <label htmlFor="floatingInputDeliveryPhone">Phone</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="Address Line 1"
                name="address1"
                value={formData.address1}
                onChange={handleChange}
                required
              />
              <label htmlFor="floatingInput">Address Line 1</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInputAddress2"
                placeholder="Address Line 2"
                name="address2"
                value={formData.address2}
                onChange={handleChange}
                required
              />
              <label htmlFor="floatingInputAddress2">Address Line 2</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInputCity"
                placeholder="City"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
              />
              <label htmlFor="floatingInputCity">City</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInputState"
                placeholder="State"
                name="state"
                value={formData.state}
                onChange={handleChange}
                required
              />
              <label htmlFor="floatingInputState">State</label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="number"
                className="form-control"
                id="floatingInputZip"
                placeholder="Zip"
                name="zip"
                value={formData.zip}
                onChange={handleChange}
                required
              />
              <label htmlFor="floatingInputZip">Zip</label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInputCountry"
                placeholder="Country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                required
              />
              <label htmlFor="floatingInputCountry">Country</label>
            </div>
            <br />
            <div className="d-grid gap-2">
              {/* <StripeCheckout
                name="Crownmania"
                image="https://avatars.githubusercontent.com/u/1486366?v=4"
                billingAddress
                shippingAddress
                description={`Your total is $${(cart?.product?.price * parseInt(qty)).toFixed(2)}`}
                amount={(cart?.product?.price * parseInt(qty)).toFixed(2) * 100}
                token={onToken}
                stripeKey={KEY}
              >
                <button type="button" className="btn whitebutton" disabled={loading}>
                  {loading ? 'Loading...' : 'Checkout'}
                </button>
              </StripeCheckout> */}
              <button type="submit" className="btn whitebutton" >
                {loading ? 'Loading...' : 'Checkout'}
              </button>
            </div>
            <br />
            <div className="d-grid gap-2">
              <Link to="/cart" className="btn whitebutton">Go Back</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Checkout
