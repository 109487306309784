import React, { useEffect, useState } from 'react'
import Img9 from '../assets/images/img9.png';
import '../assets/css/faq.css'

const FAQs = () => {
    const faqs = [
        {
          question: "What payment methods do you accept?",
          answer: "We accept all major credit and debit cards, as well as PayPal and bank transfers."
        },
        {
          question: "How can I track my order?",
          answer: "Once your order has been shipped, you will receive a tracking number via email. You can use this tracking number to monitor the status of your delivery."
        },
        {
          question: "What is your return policy?",
          answer: "We offer a hassle-free return policy within 30 days of purchase. Please visit our Returns & Exchanges page for more information."
        },
        {
          question: "Do you offer international shipping?",
          answer: "Yes, we offer international shipping to select countries. Shipping rates and delivery times may vary depending on your location."
        },
        {
          question: "How can I contact customer support?",
          answer: "Our customer support team is available via email at support@example.com or through our live chat feature on the website."
        },
        {
          question: "Are your products authentic?",
          answer: "Yes, we guarantee the authenticity of all our products. We source directly from authorized distributors and manufacturers."
        },
        {
          question: "Do you offer gift wrapping services?",
          answer: "Yes, we offer gift wrapping services for an additional fee. You can select this option during the checkout process."
        },
        {
          question: "What if my item is out of stock?",
          answer: "If an item is out of stock, you can sign up to receive notifications when it becomes available again. Alternatively, you can contact our customer support team for assistance."
        },
        {
          question: "How do I unsubscribe from marketing emails?",
          answer: "You can unsubscribe from marketing emails by clicking the 'Unsubscribe' link at the bottom of any promotional email you receive from us."
        },
        {
          question: "Do you offer discounts for bulk orders?",
          answer: "Yes, we offer discounts for bulk orders. Please contact our sales team for more information on bulk pricing and discounts."
        }
      ];
      
    const [answerVisible, setAnswerVisible] = useState(Array(faqs.length).fill(false));
    const toggleAnswer = (index) => {
        const newVisibility = [...answerVisible];
        newVisibility[index] = !newVisibility[index];
        setAnswerVisible(newVisibility);
    };

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []); 

    return (
        <div className='faqs'>
            <div id="section1">
                <img src={Img9} id="s1vid" alt='' />
                <div className="s1inner container-fluid">
                    <div className="text-center">
                        <h1 className="whitetext">FREQUENTLY ASKED QUESTIONS</h1>
                    </div>
                </div>
            </div>

            <div id="section2" className="container-fluid">
                <br />
                <ul>
                    {faqs.map((question, index) =>
                        <li className="question" key={index} onClick={() => toggleAnswer(index)}>
                            <p className="questionin">
                                {question.question}
                                <span className="questionarrow ms-1"><i className={`fa-solid fa-angle-down ${answerVisible[index] ? 'rotated' : ''}`}></i></span>
                            </p>
                            {answerVisible[index] && <p className="answer" style={{ fontSize: '0.8rem' }}>
                                {question.answer}
                            </p>}
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default FAQs
