import React, { useEffect } from "react";
import Img9 from "../assets/images/img9.png";
import "../assets/css/aboutus.css";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="aboutus">
      <div id="section1">
        <div className="s1inner container-fluid">
          <div className="text-center intro">ABOUT US</div>
        </div>
      </div>
      <div id="section2" className="container-fluid">
        <br />
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos nulla
          beatae natus tempore tenetur ab dolorem dolores culpa facilis non.
        </p>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptate ea
          provident, sunt dolor eveniet culpa mollitia suscipit quos itaque
          dignissimos in optio autem aperiam doloremque consequatur hic numquam
          temporibus? Voluptatem ut eveniet dicta in. Neque, laudantium
          necessitatibus magni quibusdam reiciendis vero aut doloremque, totam
          fugiat temporibus rerum autem qui expedita!
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
