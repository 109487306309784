import React, { useEffect, useState } from 'react'
import ClampLines from 'react-clamp-lines';
import { Link } from 'react-router-dom'
import '../assets/css/blogs.css'
import axios from 'axios';

const Blogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const getBlogsData = async () => {
        if (error === "End of Results!") {
            return;
        };
        setLoading(true);
        try {
            const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/blogs?limit=9&page=${page}`);
            setLoading(false);
            if (page == 1) {
                setBlogs(res.data);
            } else {
                setBlogs((prev) => [...prev, ...res.data]);
            }
            if (res.data.length < 9) {
                setError("End of Results!")
            };
        } catch (err) {
            setError(err.response.data.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        getBlogsData();
    }, [page]);

    const handelInfiniteScroll = async () => {
        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 1 >=
                document.documentElement.scrollHeight - 680
            ) {
                setPage((prev) => prev + 1);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handelInfiniteScroll);
        return () => window.removeEventListener("scroll", handelInfiniteScroll);
    }, []);

    return (
        <div className='blogs' style={{ minHeight: '100vh' }}>
            <div id="section1" className="container-fluid">
                <h1 className="goldtext">BLOGS</h1>
                <br />
                <div className="row" style={{ maxHeight: '1000px', overflow: 'auto' }}>
                    {!loading && blogs?.length === 0 &&
                        <h4 className='text-secondary text-center'>No blog found</h4>
                    }
                    {blogs && blogs.map((blog) => (
                        <div key={blog._id} className="col-lg-4 col-md-6 col-sm-12">
                            <Link to={`/blog/${blog._id}`} className="blogLink">
                                <div className="card" style={{ width: 'fit-content' }}>
                                    <div className="blogimg text-center">
                                        <img
                                            src={blog.thumbnailimage}
                                            className="cardimg"
                                            alt="..."
                                        />
                                    </div>
                                    <div className="card-body">
                                        <ClampLines
                                            text={blog.title}
                                            id="blog-title"
                                            lines={1}
                                            ellipsis="..."
                                            buttons={false}
                                            innerElement="p"
                                            className="card-text"
                                        />
                                        <ClampLines
                                            text={blog.content}
                                            id="blog-content"
                                            lines={2}
                                            ellipsis="..."
                                            buttons={false}
                                            innerElement="p"
                                            className="card-text"
                                        />
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                    <div style={{ minHeight: '50px' }}>
                        {loading &&
                            <div className="text-center">
                                <div className="spinner-border text-center" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        }
                        {!loading && blogs?.length !== 0 && error && <div className='text-secondary font-weight-bold text-center'>{error}</div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blogs
