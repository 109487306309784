import React from 'react'
import { Link } from 'react-router-dom'

const MorePages = () => {
    return (
        <div id="section4">
            <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 s4rc text-center">
                    <Link to='aboutus' className="s4link">
                        <div className="s4img s4img1"></div>
                        <p className="nonvisible">ABOUT US</p>
                    </Link>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 s4rc text-center">
                    <Link to='products' className="s4link">
                        <div className="s4img s4img2"></div>
                        <p className="nonvisible">SHOP</p>
                    </Link>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 s4rc text-center">
                    <Link to='contactus' className="s4link">
                        <div className="s4img s4img3"></div>
                        <p className="nonvisible">CONTACT US</p>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default MorePages
