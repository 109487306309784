import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import '../assets/css/blog.css'

const Blog = () => {
    const params = useParams();
    const [blog, setBlog] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const getBlog = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/blog/${params.blogId}`);
            setBlog(res.data.blog);
        } catch (error) {
            setError(error.response.data.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getBlog();
    }, [params.blogId]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='blog'>
            <div id="section1">
                <LazyLoadImage alt={blog?.title} src={blog?.thumbnailimage} effect='blur' width='100%' />
            </div>
            <div id="section2" className="container-fluid">
                {loading &&
                    <div className="text-center" style={{ minHeight: '95vh' }}>
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                }
                <h1 className="goldtext">{blog?.title}</h1>
                <p>
                    {blog?.content}
                </p>
            </div>
        </div>
    )
}

export default Blog
