import React, { useState, useEffect, useRef } from "react";
import logo2 from "../assets/images/logo2.png";
import vid2 from "../assets/videos/vid2.mp4";
import { Link, useLocation } from "react-router-dom";
import "../assets/css/products.css";
import axios from "axios";
import ClampLines from "react-clamp-lines";
import { useSelector } from "react-redux";

const Products = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [category, setCategory] = useState(searchParams.get("category") || "");
  const targetRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null, // Use the viewport as the root
        rootMargin: "0px", // No margin
        threshold: 0.5, // Trigger when 50% of the target is visible
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  const { collectionCategories, actionCategories } = useSelector(
    (state) => state.user
  );
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState(
    [searchParams.get("category")] || []
  );
  const [showMore, setShowMore] = useState(false);
  const [showMoreLoading, setShowMoreLoading] = useState(false);

  const getProductsData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/products`
      );
      setLoading(false);
      setProducts(res.data);
      if (res.data.length < 9) {
        setError("End of Results!");
        setShowMore(false);
      } else {
        setShowMore(true);
        setError(null);
      }
    } catch (err) {
      setError(err.response.data.message);
      setLoading(false);
    }
  };

  const handleCategoriesChange = (e) => {
    const categoryid = e.target.id;
    if (selectedCategories.includes(categoryid)) {
      setSelectedCategories(
        selectedCategories.filter((category) => category !== categoryid)
      );
    } else {
      setSelectedCategories([...selectedCategories, categoryid]);
    }
  };

  useEffect(() => {
    if (selectedCategories?.length === 0) {
      console.log("API from if getProductsData");
      getProductsData();
    } else {
      setLoading(true);
      console.log(selectedCategories);
      axios
        .get(
          `${
            process.env.REACT_APP_BACKEND_URL
          }/products?categoryid=${selectedCategories.join(",")}`
        )
        .then((res) => {
          setLoading(false);
          setProducts(res.data);
          console.log("API from else getProductsData", res.data);
          if (res.data.length < 9) {
            setError("End of Results!");
            setShowMore(false);
          } else {
            setShowMore(true);
            setError(null);
          }
        })
        .catch((err) => {
          setError(err.response.data.message);
          setLoading(false);
        });
    }
  }, [selectedCategories]);
  useEffect(() => {
    setCategory(searchParams.get("category") || "");
  }, [search]);

  useEffect(() => {
    if (category) {
      setSelectedCategories([...selectedCategories, category]);
    }
  }, [category]);

  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  const toggleFilters = () => {
    setIsFiltersVisible(!isFiltersVisible);
  };

  const onShowMoreClick = async () => {
    setShowMoreLoading(true);
    try {
      const numberOfProducts = products.length;
      const startIndex = numberOfProducts;
      const query =
        selectedCategories.length > 0
          ? `&categoryid=${selectedCategories.join(",")}`
          : "";
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/products?startIndex=${startIndex}&limit=9${query}`
      );
      if (res.data.length < 9) {
        setError("End of Results!");
        setShowMore(false);
      } else {
        setShowMore(true);
        setError(null);
      }
      setShowMoreLoading(false);
      setProducts([...products, ...res.data]);
    } catch (error) {
      setShowMoreLoading(false);
      setError("Please try again later...");
    }
  };

  useEffect(() => {
    if (isVisible) {
      onShowMoreClick();
    }
  }, [isVisible]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="products">
      <div id="section1">
        <div className="s1inner container-fluid">
          <div className="text-center intro">CROWNMANIA COLLECTIBLES</div>
        </div>
      </div>

      <div id="section1_5" className="container-fluid whitetext pt-3">
        <p className="text-end">
          <span id="filter" onClick={toggleFilters}>
            Filter <i className="fa-solid fa-filter"></i>
          </span>
        </p>
        <div
          id="s1_5filters"
          style={{ display: isFiltersVisible ? "block" : "none" }}
        >
          <h4 className="whitetext">Action Category</h4>
          <hr className="whitehr" />
          <p className="filterp">
            {actionCategories &&
              actionCategories.map((category) => (
                <span
                  key={category._id}
                  onClick={(e) => {
                    handleCategoriesChange(e);
                  }}
                  style={{
                    backgroundColor: selectedCategories.includes(category._id)
                      ? "#fff"
                      : "transparent",
                    color: selectedCategories.includes(category._id)
                      ? "#000"
                      : "#fff",
                  }}
                  className="filtertab"
                  id={category._id}
                >
                  {category.name}
                </span>
              ))}
          </p>
          <br />
          <h4 className="whitetext">Collection Category</h4>
          <hr className="whitehr" />
          <p className="filterp">
            {collectionCategories &&
              collectionCategories.map((category) => (
                <span
                  key={category._id}
                  onClick={(e) => {
                    handleCategoriesChange(e);
                  }}
                  style={{
                    backgroundColor: selectedCategories.includes(category._id)
                      ? "#fff"
                      : "transparent",
                    color: selectedCategories.includes(category._id)
                      ? "#000"
                      : "#fff",
                  }}
                  className="filtertab"
                  id={category._id}
                >
                  {category.name}
                </span>
              ))}
          </p>
          <hr className="whitehr" />
        </div>
      </div>

      <div id="section2" className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-12 s2rc1">
            <div className="s2rc1inner">
              <h4 className="whitetext">Action Category</h4>
              <hr className="whitehr" />
              <p className="filterp">
                {actionCategories &&
                  actionCategories.map((category) => (
                    <span
                      key={category._id}
                      onClick={(e) => {
                        handleCategoriesChange(e);
                      }}
                      style={{
                        backgroundColor: selectedCategories.includes(
                          category._id
                        )
                          ? "#fff"
                          : "transparent",
                        color: selectedCategories.includes(category._id)
                          ? "#000"
                          : "#fff",
                      }}
                      className="filtertab"
                      id={category._id}
                    >
                      {category.name}
                    </span>
                  ))}
              </p>
              <br />
              <h4 className="whitetext">Collection Category</h4>
              <hr className="whitehr" />
              <p className="filterp">
                {collectionCategories &&
                  collectionCategories.map((category) => (
                    <span
                      key={category._id}
                      onClick={(e) => {
                        handleCategoriesChange(e);
                      }}
                      style={{
                        backgroundColor: selectedCategories.includes(
                          category._id
                        )
                          ? "#fff"
                          : "transparent",
                        color: selectedCategories.includes(category._id)
                          ? "#000"
                          : "#fff",
                      }}
                      className="filtertab"
                      id={category._id}
                    >
                      {category.name}
                    </span>
                  ))}
              </p>
              <hr className="whitehr" />
            </div>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-12 s2rc2">
            <div
              className="row"
              style={{ maxHeight: "1000px", overflow: "auto" }}
            >
              {products &&
                products.map((product, index) => (
                  <div key={index} className="col-lg-4 col-md-6 col-sm-6">
                    <Link
                      to={`/product/${product._id}`}
                      className="productLink"
                    >
                      <div className="card" style={{ width: "fit-content" }}>
                        <div className="card-body s2cardbody1">
                          <span className="s2cardspan me-2">
                            <img src={logo2} className="s2cardlogo" alt="" />
                          </span>
                          CROWNMANIA
                        </div>
                        <div className="productimg text-center">
                          <img
                            src={product.images[0]}
                            className="cardimg"
                            alt={product.name}
                          />
                        </div>
                        <div className="card-body">
                          <ClampLines
                            text={product.name}
                            id="product-name"
                            lines={1}
                            ellipsis="..."
                            buttons={false}
                            innerElement="p"
                            className="card-text"
                          />
                          <div className="card-text d-flex justify-content-between flex-wrap">
                            <span>${product.price}</span>
                            <span className="whitetext">
                              {product?.rating.toFixed(1)}⭐
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              <div style={{ minHeight: "50px" }}>
                {loading ||
                  (showMoreLoading && (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ))}
                {!loading &&
                  !showMoreLoading &&
                  !showMore &&
                  products?.length !== 0 &&
                  error && (
                    <div className="text-secondary font-weight-bold text-center">
                      {error}
                    </div>
                  )}
                <div>
                  <div ref={targetRef}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
