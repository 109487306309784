import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { updateCart } from '../redux/user/userSlice';
import toasty from '../utils/Toast';
import '../assets/css/cart.css'

const Cart = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { currentUser, cart } = useSelector(state => state.user);
    const [qty, setQty] = useState(cart?.qty);
    const handleRemoveProduct = () => {
        dispatch(updateCart(null));
        toasty('Product removed from cart', 'success');
        navigate('/products');
    }
    useEffect(() => {
        if (!cart) {
            navigate('/products');
        }
    }, [cart]);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []); 

    return (
        <div className='cart'>
            <div id="section1" className="container-fluid">
                <h1 className="whitetext">CART</h1>
                <hr className="whitehr" />
                <div className="product">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                                <div className="col-6">
                                    <div>
                                        <img
                                            src={cart?.product?.images[0]}
                                            className="productimg"
                                            alt={cart?.product?.name}
                                        />
                                        <Link to={`/product/${cart?.product?._id}`} className="productlink" style={{ textWrap: 'nowrap' }}>{cart?.product?.name}</Link>
                                    </div>
                                </div>
                                <div className="col-6 d-flex align-items-center justify-content-center">
                                    <p className='m-0 p-0 d-flex align-items-center justify-content-center'>
                                        <i
                                            className="fa-solid fa-circle-minus quantity_management"
                                            style={{ color: '#ffffff' }}
                                            id="minus1"
                                            onClick={(e) => setQty((prev) => {
                                                if (prev > 1) {
                                                    return prev - 1;
                                                }
                                                return prev;
                                            })}
                                        ></i>
                                        <div className='text-center' style={{ minWidth: '35px'}} id="quantity1">{qty}</div>
                                        <i
                                            className="fa-solid fa-circle-plus quantity_management"
                                            style={{ color: '#ffffff' }}
                                            id="plus1"
                                            onClick={(e) => setQty((prev) => prev + 1)}
                                        ></i>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 prodcol2">
                            <div className="row">
                                <div className="col-6 d-flex align-items-center justify-content-center">
                                    <div>
                                        <button onClick={handleRemoveProduct} className="btn whitebutton">Remove</button>
                                    </div>
                                </div>
                                <div className="col-6 d-flex align-items-center justify-content-end">
                                    $<span id="price1">{cart?.product?.price}</span><span className="hidden" id="price1hid">{cart?.product?.price}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="whitehr" />
                <div className="total">
                    <div>
                        <h3 className="whitetext">Total</h3>
                    </div>
                    <div>
                        <h3 className="whitetext">${(cart?.product?.price * qty).toFixed(2)}</h3>
                    </div>
                </div>
                <br />
                <p className="text-center">
                    <Link to={`/checkout?qty=${qty}`} className="btn whitebutton">Proceed to Checkout</Link>
                </p>
            </div>
        </div>
    )
}

export default Cart
