import React from "react";
import { Link } from "react-router-dom";
import Img7 from "../../assets/images/img7.png";

const ScanQr = () => {
  return (
    <div id="section5" className="container-fluid">
      <div className="row s5r">
        <div className="col-lg-4 col-md-12 col-sm-12 s5rc1">
          <h3>GET THE BEST COLLECTION OF ITEMS</h3>
          <h3>ON CROWNMANIA</h3>
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 text-center"></div>
        <div className="col-lg-4 col-md-12 col-sm-12 s5rc2 text-end"></div>
      </div>
    </div>
  );
};

export default ScanQr;
