import React, { useEffect, useState } from 'react'
import toasty from '../utils/Toast';
import axios from 'axios';
import { useSelector } from 'react-redux';
import '../assets/css/contactus.css'

const ContactUs = () => {
  const { currentUser } = useSelector(state => state.user)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    email: currentUser?.email || '',
    query: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/contact`, formData);
      setLoading(false);
      toasty(res.data.message, "success");
      setFormData({
        email: '',
        query: ''
      });
    } catch (err) {
      setError(err.message);
      setLoading(false);
      toasty(err.response.data.message, "error")
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <div className='contactUs'>
      <div id="section1">
        <div className="form">
          <h1 className="title text-center">Contact Us</h1>
          <br />
          <form onSubmit={handleSubmit}>
            <div className="form-floating mb-3">
              <input
                type="email"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <label htmlFor="floatingInput">Email address</label>
            </div>
            <div className="form-floating mb-3">
              <textarea
                className="form-control"
                placeholder="Leave a comment here"
                name='query'
                id="floatingTextarea2"
                style={{ height: '100px' }}
                value={formData.query}
                onChange={handleChange}
                required
              ></textarea>
              <label htmlFor="floatingTextarea2">Comments</label>
            </div>
            <div className="d-grid gap-2">
              <button type="submit" className="btn whitebutton" disabled={loading}>{loading ? 'Submitting' : 'Submit'}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
