import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import toasty from "../../utils/Toast";
// import { Modal } from 'bootstrap';
import { Modal, Button } from "react-bootstrap";
const OrderCard = ({ order }) => {
  const navigate = useNavigate();
  // const modalRef = useRef();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const [bsModal, setBsModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState(null);
  // useEffect(() => {
  //     if (modalRef.current) {
  //         setBsModal(new Modal(modalRef.current));
  //     }
  // }, []);
  useEffect(() => {
    const getProductDetails = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/product/${order.productid}`
        );
        setProductData(res.data.product);
        setLoading(false);
      } catch (err) {
        // console.log(err.response.data.message);
        setLoading(false);
      }
    };
    getProductDetails();
  }, [order]);

  // Define your states
  const [replacementReason, setReplacementReason] = useState("");
  const [issueDescription, setIssueDescription] = useState("");
  const [replacementLoading, setReplacementLoading] = useState(false);
  // Handle form submission
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setReplacementLoading(true);
    try {
      const response = await axios.post("/replacement", {
        returnReason: replacementReason,
        issueDescription: issueDescription,
        orderid: order._id,
      });
      toasty(response.data.message, "success");
      navigate(`/replacement?replacementid=${response.data.replacementid}`);
    } catch (error) {
      toasty(error.response.data.message, "error");
      navigate(`/replacement`);
    } finally {
      handleClose();
      setReplacementLoading(false);
    }
  };

  return (
    <>
      {/* {loading && <Loader />} */}
      {!loading && productData && (
        <div className="order mb-3">
          <p className="whitetext">
            Ordered on{" "}
            {new Date(order.orderDate).toLocaleDateString(undefined, {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
          <Link to={`/product/${order.productid}`} className="product">
            <div>
              <img
                src={productData.images[0]}
                className="prodimg"
                alt={productData.name}
              />
            </div>
            <p>
              <strong className="whitetext">Product: </strong>{" "}
              {productData.name}
              <br />
              <strong className="whitetext">Quantity: </strong> {order.quantity}
              <br />
              <strong className="whitetext">Order Value: </strong>${" "}
              {order.totalAmount}
            </p>
          </Link>
          <p>
            <strong className="whitetext" style={{ fontSize: "25px" }}>
              Delivery Details{" "}
            </strong>
            <br />
            <strong className="whitetext">Name: </strong> {order.deliveryName}
            <br />
            <strong className="whitetext">Phone: </strong> {order.deliveryPhone}
            <br />
            <strong className="whitetext">Address: </strong> {order.address1},{" "}
            {order.address2}, {order.city}, {order.state}, {order.country},{" "}
            {order.zip}
            <br />
            <strong
              className="whitetext"
              style={{ textTransform: "capitalize" }}
            >
              Status:{" "}
            </strong>{" "}
            {order.status}
            <br />
            {order.status === "shipped" && (
              <>
                <strong className="whitetext">Tracking Id: </strong>
                {order.trackingid}
                <br />
                <strong className="whitetext">Tracking Company: </strong>
                {order.trackingcompany}
                <br />
                <strong className="whitetext">Tracking URL: </strong>
                <a href={order.trackinglink}>{order.trackinglink}</a>
                <br />
              </>
            )}
            {order.type === "return" && (
              <div>
                <p>Replaced</p>
                <strong className="whitetext">Replacement Id: </strong>
                {order.returnid}
                <br />
              </div>
            )}
          </p>
          {order.type === "primary" && (
            <div className="btn whitebutton buttonlink" onClick={handleShow}>
              Replace my Order
            </div>
          )}

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Reason for replacement?</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleFormSubmit}>
              <Modal.Body>
                <div className="mb-3">
                  <label htmlFor="replacementReason" className="form-label">
                    Reason for Replacement
                  </label>
                  <select
                    defaultValue={"others"}
                    style={{ color: "black" }}
                    className="form-select"
                    id="replacementReason"
                    value={replacementReason}
                    onChange={(e) => setReplacementReason(e.target.value)}
                    required
                  >
                    <option>Choose...</option>
                    <option value="bad-quality">Reason 1</option>
                    <option value="late-delivery">Reason 2</option>
                    <option value="others">Others(Please specify...)</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="issueDescription" className="form-label">
                    Describe Your Issue
                  </label>
                  <textarea
                    className="form-control"
                    style={{ color: "black" }}
                    id="issueDescription"
                    rows="3"
                    value={issueDescription}
                    onChange={(e) => setIssueDescription(e.target.value)}
                    required
                  ></textarea>
                </div>
              </Modal.Body>
              <Modal.Footer style={{ justifyContent: "space-between" }}>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                  disabled={replacementLoading}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ backgroundColor: "#000635" }}
                  disabled={replacementLoading}
                >
                  {replacementLoading ? "Confirming" : "Confirm Replacement"}
                </button>
              </Modal.Footer>
            </form>
          </Modal>
        </div>
      )}
    </>
  );
};

export default OrderCard;
