import React, { useEffect } from 'react'
import TopPicks from '../components/home/TopPicks'
import Banner from '../components/home/Banner'
import LatestCollections from '../components/home/LatestCollections'
import MorePages from '../components/home/MorePages'
import ScanQr from '../components/home/ScanQr'
import '../assets/css/index.css'

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='homePage'>
      <Banner />
      <LatestCollections />
      <TopPicks />
      <MorePages />
      <ScanQr />
    </div>
  )
}

export default Home
