import React, { useEffect, useState } from 'react'
import '../assets/css/login.css'
import { useLocation, useNavigate } from 'react-router-dom';
import toasty from '../utils/Toast';
import axios from 'axios';

const EmailUnverified = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const email = searchParams.get('email') || '';
    const [loading, setLoading] = useState(false);
    const handleResend = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/resendEmailVerify/${email}`);
            toasty(res.data.message, "success");
            navigate('/login')
        } catch (error) {
            toasty(error.response.data.message, "error");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='login'>
            <div id="section1" className="container-fluid">
                <div className="form">
                    <h1 className="text-center">EMAIL UNVERIFIED</h1>
                    <br />
                    <div>
                        <div className="mb-3 text-center">
                            Please verify your email to continue!
                        </div>
                        <div className="d-grid gap-2">
                            <button onClick={handleResend} disabled={loading} className="btn whitebutton">
                                Resend Verification Email
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailUnverified
