import React, { useEffect, useState } from 'react'
import toasty from '../utils/Toast';
import axios from 'axios';
import '../assets/css/login.css'
import { Link, useNavigate } from 'react-router-dom';
import { loginSuccess } from '../redux/user/userSlice';
import { useDispatch } from 'react-redux';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });

    const handleTogglePassword = () => {
        setShowPassword(prevState => !prevState);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`, formData);
            setLoading(false);
            toasty(res.data.message, "success");
            dispatch(loginSuccess(res.data.user));
            setFormData({
                username: '',
                password: ''
            });
            navigate('/');
        } catch (err) {
            setLoading(false);
            setError(err.response.data.message);
            toasty(err.response.data.message, "error");
            if (err.response.data.message === "Email unverified!") {
                navigate(`/verifyemail?email=${err.response.data.user.email}`);
                return
            }
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="login">
            <div id="section1" className="container-fluid">
                <div className="form">
                    <h1 className="text-center">LOGIN</h1>
                    <br />
                    <form onSubmit={handleSubmit}>
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Username"
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="floatingInput">Username</label>
                        </div>
                        <div className="form-floating">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                className="form-control"
                                id="password"
                                placeholder="Password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="password">Password</label>
                        </div>
                        <br />
                        <div className="mb-3 form-check">
                            <input type="checkbox" className="form-check-input" id="check" onChange={handleTogglePassword}
                                checked={showPassword} />
                            <label className="form-check-label" htmlFor="check">Show Password</label>
                        </div>
                        <div className="d-grid gap-2">
                            <button type="submit" className="btn whitebutton" disabled={loading}>
                                {loading ? 'Loading...' : 'Login'}
                            </button>
                        </div>
                        <br />
                        <p className="text-center fp">
                            <Link to="/forgotpassword" className="fpa">Forgot Password?</Link>
                        </p>
                        <p className="text-center or">OR</p>
                        <div className="d-grid gap-2">
                            <Link to="/signup" className="btn whitebutton">Sign Up</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login
