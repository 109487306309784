import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import durkimg4 from "../assets/images/durkimg4.jpg";
import durkimg3 from "../assets/images/durkimg3.jpg";
import "../assets/css/product.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateCart } from "../redux/user/userSlice";

const Product = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [product, setProduct] = useState();
  const [qty, setQty] = useState(1);
  const [artist, setArtist] = useState();
  const [reviews, setReviews] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const getProduct = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/product/${params.productId}`
      );
      setProduct(res.data.product);
      setArtist(res.data.artist);
      setReviews(res.data.reviews);
    } catch (error) {
      setError(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProduct();
  }, [params.productId]);

  const handleCheckout = () => {
    dispatch(updateCart({ product: product, qty: qty, artist: artist }));
    navigate("/cart");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <div className="product">
      <div className="container-fluid" id="section1">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div id="carouselExample" className="carousel slide">
              <div className="carousel-inner">
                {product?.images &&
                  product.images.map((image, index) => (
                    <div
                      key={index}
                      className={`carousel-item ${index === 0 ? "active" : ""}`}
                    >
                      <LazyLoadImage
                        src={image}
                        className="d-block carimg"
                        alt="..."
                        effect="blur"
                      />
                    </div>
                  ))}
                {loading && (
                  <>
                    <div className="carousel-item active text-center">
                      <div
                        className="progress carimg"
                        style={{ width: "70%" }}
                        role="progressbar"
                        aria-label="Animated striped example"
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <div
                          class="progress-bar progress-bar-striped progress-bar-animated bg-dark"
                          style={{ width: "100%" }}
                        ></div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExample"
                data-bs-slide="prev"
              >
                <span>
                  <i
                    className="fa-solid fa-chevron-left fa-3x"
                    style={{ color: "white" }}
                  ></i>
                </span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExample"
                data-bs-slide="next"
              >
                <span>
                  <i
                    className="fa-solid fa-chevron-right fa-3x"
                    style={{ color: "white" }}
                  ></i>
                </span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            {!loading && product && (
              <div className="proddetails">
                <br />
                <h2>{product?.name}</h2>
                <h1 className="title normaltext">$ {product?.price}</h1>
                <br />
                <h5>
                  <span className="whitetext">
                    {product?.rating.toFixed(1)}
                  </span>
                  ⭐<span>({reviews?.length} reviews)</span>
                </h5>
                <br />
                <div className="d-flex gap-2 align-items-center">
                  <span className="fw-semibold fs-5">Quantity</span>
                  <button
                    type="button"
                    className="btn btn-outline-secondary shadow bg-light"
                    onClick={(e) =>
                      setQty((prev) => {
                        if (prev > 1) {
                          return prev - 1;
                        }
                        return prev;
                      })
                    }
                  >
                    -
                  </button>
                  <span className="text-center" style={{ minWidth: "26px" }}>
                    {qty}
                  </span>
                  <button
                    type="button"
                    className="btn btn-outline-secondary shadow bg-light"
                    onClick={(e) => setQty((prev) => prev + 1)}
                  >
                    +
                  </button>
                </div>
                <div className="d-grid gap-2 mt-3">
                  <button onClick={handleCheckout} className="btn whitebutton">
                    Buy Now
                  </button>
                </div>
                <br />
                <h3>
                  <strong>Product Description</strong>
                </h3>
                <hr className="hrDefault" />
                <p>{product?.description}</p>
                <br />
                <h3>
                  <strong>Reviews</strong>
                </h3>
                <hr className="hrDefault" />
                {reviews &&
                  reviews.map((review) => (
                    <p key={review._id}>
                      <strong>{review.name}</strong>{" "}
                      <span className="whiteentext">
                        {review.rating.toFixed(1)}
                      </span>
                      ⭐{/* <br /> */}
                      <span>{review.review}</span>
                    </p>
                  ))}
              </div>
            )}
            {loading && (
              <div className="text-center" style={{ minHeight: "95vh" }}>
                <div className="spinner-border text-center" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
