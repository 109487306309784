import React, { useEffect } from 'react'
import durkimg1 from '../assets/images/durkimg1.png';
import '../assets/css/minting.css'

const Minting = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <div className='minting'>
      <div id="section1" className="container-fluid">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 col1 text-center">
          <p>
            <img src={durkimg1} className="nftimg" alt="" />
          </p>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col2">
          <div>
            <h2 className="whitetext">Mint your Action Figure NFT</h2>
            <br />
            <p>
              <strong>Product:</strong> Product Name
              <br />
              <strong>Creator:</strong> Artist name
              <br />
              <strong>Price:</strong> $20
            </p>
            <br />
            <a href="#" className="btn whitebutton">Min NFT</a>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Minting
