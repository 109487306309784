import React, { useEffect, useState } from "react";
import "../assets/css/login.css";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateCart } from "../redux/user/userSlice";

const OrderSuccessfull = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(search);
  const orderid = searchParams.get("orderid");

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(updateCart(null));
  }, []);

  return (
    <div className="login">
      <div id="section1" className="container-fluid">
        {orderid &&
          <div className="form">
            <h1 className="text-center">Order Received</h1>
            <br />
            <p className="text-center">We have received your order.</p>
            <p className="text-center">Your order id is {orderid}.</p>
            <br />
            <div className="d-grid gap-2">
              <Link to="/profile" className="btn whitebutton">
                View Orders
              </Link>
            </div>
          </div>
        }

        {!orderid &&
          <div className="form">
            <h1 className="text-center">Error receiving order status</h1>
            <br />
            <p className="text-center">Please check after some time...</p>
            <br />
            <div className="d-grid gap-2">
              <Link to="/profile" className="btn whitebutton">
                View Orders
              </Link>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default OrderSuccessfull;
